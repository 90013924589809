<template>
  <b-card>
    <b-badge variant="light-secondary">To be continued...</b-badge>
  </b-card>
</template>

<script>
export default {
  name: 'Todo'
}
</script>

<style scoped>

</style>
